import React, { useState, useEffect } from "react"
import {BrowserRouter as Router} from "react-router-dom";
import {Switch, Route} from "react-router-dom";
import ARSetup from './arsetup';
import APSetup from './apsetup'; 
import Invoice from './invoice';
import Receivable from './areceivable';
import ARPayment from './arpayment';
import EnterBill from './enterbill';
import Payable from './payable';

// import PID from './PaymentID';
// import Parent from './parent';
// import ARPay from './ARPay';
// import selectedpay from './selectedpay';
import AccountPage from './accounthome';

const App = () => {
  return(
   <div>
     <Router>
      <Switch>
        <Route exact path="/" component={AccountPage} />
        <Route path="/arsetup" component={ARSetup} />
        <Route path="/apsetup" component={APSetup} />
        <Route path="/invoice" component={Invoice} />
        <Route path="/areceivable" component={Receivable} />
        <Route path="/arpayment" component={ARPayment} />
        <Route path="/enterbill" component={EnterBill} />
        <Route path="/payable" component={Payable} />
      </Switch>
     </Router> 
    </div>
 /*   <div>
      <EnterBill />
      <route path="/enterbill" component={EnterBill} />

      <Payable />
      <Route path="/payable" component={Payable} />
    </div>
    <div>
    <PID />
    <route path="/PaymentID" component={PID} />
    </div>
    *<div>
    <Parent /> 
    <Route path="/parent" component={Parent} />
    </div>
    <div>
       <ARPay />
       <route path="/ARPay" component={ARPay} />
    </div>*/
    // <div>
    //    <selectedpay />
    //    <route path="/selectedpay" component={selectedpay} />
    // </div>

  );
} ;
  
export default App;