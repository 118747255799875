import React from 'react'
import TextField from '@material-ui/core/TextField'

import * as _ from 'lodash'

export function isDateInRange(dateFrom, dateTo, dateToBeFiltered) {

  const parsedDateToBeFiltered = parseDateWithDots(dateToBeFiltered)
  const parsedDateTo = parseDateWithDots(dateToBeFiltered, true)
  console.log(parsedDateToBeFiltered)

  if (!parsedDateToBeFiltered) {
    return !(dateFrom || dateTo)
  }

  if (!(dateFrom || dateTo)) {
    return true
  } else if (!dateFrom) {
    return parsedDateTo <= new Date(dateTo)
  } else if (!dateTo) {
    return parsedDateToBeFiltered >= new Date(dateFrom)
  }
  return parsedDateToBeFiltered >= new Date(dateFrom)
    && parsedDateTo <= new Date(dateTo)
}

export function parseDateWithDots(dateString, dateTo) {
  if (!dateString) {
    return null
  }

  if (dateString.includes(' ')) {
    dateString = dateString.split(' ')[0]
  }

  const dateArray = dateString.split('/')

  if (dateTo){
    return new Date(dateArray[2], dateArray[0] - 1, dateArray[1] - 1)
  }

  console.log(dateArray)
  console.log(new Date(dateArray[2], dateArray[0] - 1, dateArray[1]))
  return new Date(dateArray[2], dateArray[0] - 1, dateArray[1])
}



export const FilterNumericBetween = (props) => {
  const { columnDef, onFilterChanged } = props
  return (
    <>
      <TextField
        type={columnDef.type === 'date' ? 'date' : 'date'}
        value={_.get(columnDef, ['tableData', 'filterValue', 'dateFrom']) || ''}
        label="Start Date"
        placeholder={columnDef.filterPlaceholder || ''}
        style={{marginRight: 10}}
        onChange={(event) => {
          let value = {...columnDef.tableData.filterValue}
          value.dateFrom = event.target.value
          onFilterChanged(columnDef.tableData.id, value)
        }}
        InputLabelProps={{
         shrink: true,
       }}
      />

      <TextField
        type={columnDef.type === 'date' ? 'date' : 'date'}
        value={_.get(columnDef, ['tableData', 'filterValue', 'dateTo']) || ''}
        label="End Date"
        placeholder={columnDef.filterPlaceholder || ''}
        onChange={(event) => {
          let value = {...columnDef.tableData.filterValue}
          value.dateTo = event.target.value
          onFilterChanged(columnDef.tableData.id, value)
        }}
        InputLabelProps={{
         shrink: true,
       }}
      />
    </>
  )
}
