import React, {useState} from 'react';
import MaterialTable from 'material-table';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import tableIcons from "./components/MaterialTableIcons"
import {Form, FormGroup} from 'react-bootstrap';
import Select from '@material-ui/core/Select';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

 const useStyles = makeStyles({
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
    },
    title: {
      fontSize: '2em'
    }
  });

 const theme = createMuiTheme({
    typography: { h6: {fontSize: '1.4em'}},
  });


  const tableStyles = makeStyles({
    root: {justifyContent: 'space-evenly'},
    spacing: {justifyContent: 'space-evenly'}

  });

const columns=[
    { title: 'CASE ID #', field:'cid', editable: 'never'},
    { title: 'Processor Name', field:'pname'},
    { title: 'Shipped Date', field:'sdate', editable: 'never'},
    { title: 'Process ID #', field:'pid'},      
    { title: 'AMT', field:'tamt', type : 'currency'},
  ]

const PaymentID = (inputs) => (
  

  // return (
      
      <div className="App">
      <h1 align="center">Lento Accounts</h1>
      <MaterialTable 
      title={"Processor Name : "+ inputs.name + "  Payment ID :" +inputs.id}
      icons={tableIcons}
      columns={columns} 
      data={inputs.data}
      // onSelectionChange={(selectedRows)=>console.log(selectedRows)}
      options={{
        sorting:true,
        search:false,
        searchFieldAlignment:"left",
        searchAutoFocus:true,
        searchFieldVariant:"outlined",
        filtering:false,
        paging:true,
        pageSizeOptions:[2,5,10,15,77],
        pageSize:5,
        paginationType:"stepped",
        showFirstLastPageButtons:false,
        paginationPosition:"bottom",
        exportButton:true,
        exportAllData:true,
        exportFileName:"TableData",
        addRowPosition:"first",
        actionsColumnIndex:-1,
        selection:false,
        showSelectAllCheckbox:false,
        showTextRowsSelected:false,
        grouping:false,
        columnsButton:true,
        rowStyle:(data,index)=>index%2==0?{background:"#f5f5f5"}:null,
        headerStyle:{background:"lightgray",fontStyle:"italic"},
        selectionProps:rowData=>({
            disabled:rowData.tax==null,
            color:"primary"
            }),

        }}      
       />
    <h2>Total Amount : ${inputs.sum}</h2>
    </div>

  );

export default PaymentID;
