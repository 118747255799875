import React, { useState,  forwardRef, useContext, useEffect } from 'react';
import MaterialTable from 'material-table';
import tableIcons from "./components/MaterialTableIcons"
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Button} from 'react-bootstrap';
//log in 
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { api_url } from "./authorize";
// import { AuthorizeContext } from "../../RootContext";
import {Form, FormGroup, ControlLabel, FormControl, Modal} from 'react-bootstrap';
// import Button from '@material-ui/core/Button';
// import { Link, useParams } from 'react-router-dom';
// import logo from './../../img/lento_logo.jpg';
// import styles from './../../styles';
import DateFnsUtils from '@date-io/date-fns';
import { useHistory } from 'react-router-dom';
// import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
// import tableIcons from "./components/MaterialTableIcons"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import BillDetais from './payable';
import ARID from './ARID'
import Grid from '@material-ui/core/Grid';
const tableStyles = makeStyles({
    root: {justifyContent: 'space-evenly'},
    spacing: {justifyContent: 'space-evenly'}
  });
const useStyles = makeStyles({
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
    },
    title: {
      fontSize: '2em'
    }
  });
 const theme = createMuiTheme({
    typography: { h6: {fontSize: '1.4em'}},
  });
const numberWithCommas = (x) => {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
const Receivable  = () => {
  const tableClass = tableStyles()
  const classes = useStyles();
  // const { token, role, setAuthBody, setAuthenticated, authBody } = useContext(AuthorizeContext);//login
  const [loading, setLoading] = useState(true) //login
  // const [profile, setProfile] = useState(authBody || {role: "visitor"})
  const [open, setOpen] = useState(false)
  const [selectedRows, setSelectedRows] = useState({})
  let [chosen, setChosen] = useState("","");
const history = useHistory();
const goBack = () => {
  history.goBack();
 };
  //Data for Account Payable
  // const [tableData,setTableData]=useState([])
  
  // const hasRole = (profile, roles) =>{
  // return roles.includes(role)
  // }
  //     console.log(tableData)
//   const sam = {
//     "_id": "648ba3a1cc891d1d770b82cd",
//     "Date_Issued": "06/15/2023",
//     "Due_Date": "07/30/2023",
//     "Invoice_URL": "",
//     "Packaging_URL": "",
//     "Payment_ID": "",
//     "Invoice_ID": "PI-00001",
//     "Cases": [
//         "K8psf1L"
//     ],
//     "Total_AMT": "2",
//     "Current_AMT": "2",
//     "Billing_Info": "AR003",
//     "Shipping_Info": "Distributor",
//     "payment_status": "UNPAID",
//     "packing_status": "open",
//     "Paid_Date": "",
//     "tableData": {
//         "id": 0
//     }
// }
  //Open Dialog Pop-up
  const handleClickOpen = (chosen) =>() => {
      setOpen(true);
      console.log(chosen)
      setChosen(chosen);
    };
  //setOpen(false) meanning is close Dialog
  const handleClose = (e) => {
    e.preventDefault()
    setOpen(false)
  }
  const onChange = (option) => {
    const { name, value } = option.target
    setTableData({...tableData,
    // setAcct({...acct,
      [name] : value
    })
  }
  const [tableData,setTableData]=useState([])
  // API CALLS
  // Call to populate table data
   useEffect(() => {
    const fetchData = async () => {
      const res = await axios({
        url: `https://${api_url}/api/packing_invoice?lookup=AR`,
        method: 'get',
        // headers: {
        //   'x-access-token' : token
        // }
      }).then(
        res => {
          console.log(res.data)
          if(res.data.message){
            alert(res.data.message)
          }
          else if(res.data.validation){
            alert(res.data.validation.replace("_", " "))
          }
          else if(res.data.notLoggedIn){
            // setAuthenticated(false)
            // setAuthBody({role: 'visitor'})
            alert("You have been logged out due to inactivity. Please log in again.")
          }
          else{
            setLoading(false)
            setTableData(res.data)
            console.log(res.data)
          }
        }
      );

    }
    fetchData();
  }, []);
  const columns = [
      { title: 'Customer Name', field:'Customer_Name', align: 'center',  editable: "never", render:tableData => {
          return (
            <div>
            <Button variant="contained"
            color="primary"
            onClick={handleClickOpen(tableData)}>
            {tableData.Customer_Name}
            </Button>
            </div>
            )},
            cellStyle:{ fontSize: '.8em'}},
      { title: 'Invoice Amount', field:'Invoice_Sum', align:'right', editable: "never", render: rowData => {
          return (
            <div>${numberWithCommas(Number.parseFloat(rowData.Invoice_Sum).toFixed(2))}</div>
          )
        }, cellStyle:{ fontSize: '.8em'}
    },
    { title: 'Due Amount', field:'Total_Due_Sum', align: "right", editable: "never", render: rowData => {
        return (
          <div style={{textAlign: 'right'}}
          >${numberWithCommas(Number.parseFloat(rowData.Total_Due_Sum).toFixed(2))}</div>
        )
      }, cellStyle:{ fontSize: '.8em'}
      },
      { title: 'Current Amount', field:'Current_Due_Sum', align:'right', editable: "never",
        render: rowData => {
          return (
            <div style={{textAlign: 'right'}}
            >
            ${numberWithCommas(Number.parseFloat(rowData.Current_Due_Sum).toFixed(2))}
            </div>
          )
        }, cellStyle:{ fontSize: '.8em', marginRight: 45}
    },

      { title: 'Past Due Amount', field:'Past_Due_Sum', align:'right',
        editable: "never",
        render: rowData => {
          return (
            <div style={{textAlign: 'right'}}
            >
            ${numberWithCommas(Number.parseFloat(rowData.Past_Due_Sum).toFixed(2))}
            </div>
          )
        }, cellStyle:{ fontSize: '.8em', marginRight: 45}
      }
    ]


const starting_from_DB = 10005;

let unique = [];

function calculate_pid(starting){
    // create empty list variables
    let names = [];
    let names_ids = [];
    // grabbing the names from selected row data
    rowSelection.forEach((row) => names.push(row.pname));
    // create a set of the name to eliminate duplicates
    const unique = [...new Set(names)];
    // combining the unique names with a unique ID
    unique.forEach((name,index) => names_ids.push([name, starting+index]))

    console.log(names_ids)
    // Loop thru and check the names to assign unique ID's
    names_ids.forEach((person, index) =>{
       rowSelection.forEach((row)=> {
            if (row.pname === person[0]){
               row.pid = person[1]
            } 
          })
    });

}

  
  function handleDateClick(){
    calculate_pid(starting_from_DB);
    let rows = rowSelection;
    //if not selected any checkbox, Pop-up Alert message
    if (rows.length === 0) {
      alert('Please select at least one column.');
    } else {
    // Perform some action with the selected columns
      console.log('Selected columns:', rows);
    }
    //brig current date information and put "theDate"
    const theDate = new Date().toLocaleDateString();
    //put date in the selected rows
    rows.forEach((row) => row.pdate = theDate);
    setRowSelection([]);
    rows = []

  };
const [rowSelection, setRowSelection] = useState([]);

return(
    <div className="Receivable">
      <h1 align="center">Receivable</h1>
      <button onClick={goBack}>
      Back
      </button>
      <ThemeProvider theme={theme}>
      <MaterialTable 
      title="*Accounts Receivable"
      icons={tableIcons}
      columns={columns} 
      data={tableData} 
      //update "Paid Amt", "Pay Date", "Ref"
      //Grab SelectedRows and showing up console for web page
      onSelectionChange={(selectedRows)=>setSelectedRows(selectedRows)}
      // onSelectionChange={(selectedRows)=>console.log(selectedRows)}
      options={{
        sorting:true,
        search:true,
        searchFieldAlignment:"right",
        searchAutoFocus:true,
        searchFieldVariant:"outlined",
        filtering:false,
        paging:true,
        pageSizeOptions:[2,5,10,15,50],
        pageSize:5,
        paginationType:"stepped",
        showFirstLastPageButtons:false,
        paginationPosition:"bottom",
        exportButton:{
           csv: true,
        },
        exportAllData:true,
        exportFileName: "Receivable",
        addRowPosition:"first",
        actionsColumnIndex:-1,
        selection:false,
        showSelectAllCheckbox:false,
        showTextRowsSelected:false,
        grouping:false,
        columnsButton:true,
        rowStyle:(data,index)=>index%2==0?{background:"#f5f5f5"}:null,
        headerStyle:{background:"lightgray",fontStyle:"italic",fontSize:19},
        selectionProps:rowData=>({
            disabled:rowData.tax==null,
            color:"primary"
            }),
        }}      
       />
     </ThemeProvider>
    {/*After Click Add button(Pop-up)*/}
    <Dialog open={open} onClose={!open} fullScreen aria-labelledby="form-dialog-title" width={900} height={300} color="primary">
      <DialogTitle id="form-dialog-title">
        {/*<span style={{ fontSize: '1em'}}>{chosen[1]}, Payment ID : {chosen[0]}</span>*/}
      </DialogTitle>
    {/*Display Payment ID with Purple Button and connecte Data from AP Fuction*/}
      <DialogContent>
          <div>
              <ARID 
                          data={chosen.Unpaid_invoices} 
                         name={chosen.Customer_Name}
                         id={"Receivable doesn't need Customer ID"} 
                         sum={chosen.Total_Due_Sum}
              />                  
          </div>
       <DialogActions
          disableSpacing
          classes={{
            root: tableClass.root,
            spacing: tableClass.spacing
          }}
        >
        {/*Close Dialog after click 'Close' button*/}
          <button className="btn btn-primary"
          onClick={handleClose} style={{fontSize: '2em'}}>
            Close
          </button>
       </DialogActions>
      </DialogContent>
    </Dialog>
     </div>
    );
}

export default Receivable;