import React, { useState,  forwardRef, useContext, useEffect } from 'react';
import MaterialTable from 'material-table';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import tableIcons from "./components/MaterialTableIcons"
import {Form, FormGroup} from 'react-bootstrap';
import Select from '@material-ui/core/Select';
import 'jspdf-autotable';
//Pop-up Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import { pdf } from '@react-pdf/renderer';//PDF
// import InvoicePDF from '../../components/Accounting/InvoicePDF'// PDF
import Button from '@material-ui/core/Button';
import { isDateInRange, FilterNumericBetween} from './invoiceDateRange';
import { useParams } from 'react-router-dom';
import PrintIcon from '@material-ui/icons/Print';
import EmailIcon from '@material-ui/icons/Email';
import Check from '@material-ui/icons/Check';
// import moment from 'moment';
import Receipt from '@material-ui/icons/Receipt';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import SendIcon from '@material-ui/icons/Send';
import InvoiceList from './invoicelist';
import { useHistory } from 'react-router-dom';
import { api_url } from "./authorize";
import axios from 'axios';

 const useStyles = makeStyles({
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
    },
    title: {
      fontSize: '2em'
    }
  });
 //table Theme
 const theme = createMuiTheme({
    typography: { h6: {fontSize: '1.4em'}},
  });
 const tableStyles = makeStyles({
    root: {justifyContent: 'space-evenly'},
    spacing: {justifyContent: 'space-evenly'}
  });
 
function Invoice() {
 // const { token, role, setAuthBody, setAuthenticated, authBody } = useContext(AuthorizeContext);//login
 // const [loading, setLoading] = useState(true) //login
const history = useHistory();
 const goBack = () => {
    history.goBack();
  };
 const [email, setEmail] = useState([])
 const [data, setData] = useState({})
 //Open Dialog
 const [open, setOpen] = useState(false)//add
 //Make tableStyle
 const tableClass = tableStyles() //add
 const [emailButton, setEmailButton] = useState(true)
  let rowTitle = ""
  let total, date, pdfData = ""
  let something = true
  let fileURLs = []
  let payStubs = []
  let dataRows = []
  let rows = []
  // let columns = []
  let dataColumns = []
  let invColumns = []
  let { surgeonid } = useParams()
  let [chosen, setChosen] = useState("","");


useEffect(() => {
    const fetchData = async () => {
      const res = await axios({
        url: `https://${api_url}/api/packing_invoice`,
        method: 'get',
        // headers: {
        //   'x-access-token' : token
        // }
      }).then(
        res => {

          if(res.data.message){
            alert(res.data.message)
          }
          else if(res.data.validation){
            alert(res.data.validation.replace("_", " "))
          }
          else if(res.data.notLoggedIn){
            // setAuthenticated(false)
            // setAuthBody({role: 'visitor'})
            alert("You have been logged out due to inactivity. Please log in again.")
          }
          else{
            // setLoading(false)
            setTableData(res.data)
            console.log(res.data)
          }
        }
      );
    }
    fetchData();
  }, []);

 const handleSubmit = (e) => {
    e.preventDefault()
    const data =  [...tableData];}
 //setOpen(false) meanning is close Dialog
 const handleClose = (e) => {
    e.preventDefault()
    setTableData({})
    setOpen(false)    
    window.location.reload()

  }



  //Kind of Dictionary (ex if you got "MQC" change name for show "N35"
  const onChange = (option) => {
    const { name, value } = option.target
    setTableData({...tableData,
      [name] : value
    })
  }

 const handleClickOpen = (chosen) =>() => {
      
      setOpen(true);
      console.log(chosen)
      setChosen(chosen);
    };

 const numberWithCommas = (x) => {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

 //tables Data --> should be move to table of DB
 const [tableData,setTableData]=useState([])

//Table looks like this
  // const [state, setState] = useState({
  const columns=[
      {
  title: 'Invoice #',
  field: 'Invoice_URL',
  editable: 'never',
  align: 'center',
  render: (rowData) => {
    const displayPdfDocument = () => {
      // Fetch the PDF data from the database using the URL or any other identifier
      fetch(rowData.Invoice_URL)
        .then((response) => response.blob())
        .then((blob) => {
          // Create a new URL for the PDF blob
          const fileURL = URL.createObjectURL(blob);
          // Open the PDF in a new tab or window
          window.open(fileURL, '_blank');
        })
        .catch((error) => {
          // Handle any errors that occur during fetching or displaying the PDF
          console.error('Error loading PDF:', error);
        });
    };

    return (
      <Button
        color="primary"
        onClick={displayPdfDocument}
        style={{ fontSize: '1em', marginRight: '2em' }}
      >
        {rowData.Invoice_ID}
      </Button>
    );
  },
  cellStyle: { fontSize: '1em' },
  defaultSort: 'desc'
},
      
      { title: 'Date', field:'Date_Issued', type: 'numeric', editable: "never",
        align: 'center', cellStyle:{ fontSize: '1em'},},
        {
   title: 'Packing #', field:'Packaging_URL', editable: "never", align: 'center',
  render: (rowData) => {
    const displayPdfDocument = () => {
      // Fetch the PDF data from the database using the URL or any other identifier
      fetch(rowData.Packaging_URL)
        .then((response) => response.blob())
        .then((blob) => {
          // Create a new URL for the PDF blob
          const fileURL = URL.createObjectURL(blob);
          // Open the PDF in a new tab or window
          window.open(fileURL, '_blank');
        })
        .catch((error) => {
          // Handle any errors that occur during fetching or displaying the PDF
          console.error('Error loading PDF:', error);
        });
    };

    return (
      <Button
        color="primary"
        onClick={displayPdfDocument}
        style={{ fontSize: '1em', marginRight: '2em' }}
      >
        {rowData.Invoice_ID}
      </Button>
    );
  },
  cellStyle: { fontSize: '1em' },
  defaultSort: 'desc'
},
      { title: 'Customer Name', field:'Customer_Name', editable: "never", render:tableData => {
          return (
            <div>
            <Button variant="contained"
            color="primary"
            onClick={handleClickOpen(tableData)}>
              {tableData.Customer_Name}
            </Button>
            </div>
            )},
            cellStyle:{ fontSize: '.8em'}},
      { title: 'Account #', field:'Acct_No', editable: "never", cellStyle:{ fontSize: '1em'}},
      { title: 'Term', field:'Term', editable: "never",  type: 'date', cellStyle:{ fontSize: '1em'}, filtering: false},
      { title: 'Contract/PO', field: 'PO_number', editable: 'never', filtering: false, cellStyle:{ fontSize: '1em'}},
      { title: 'Invoice Amt.', field:'Total_AMT', editable: "never", cellStyle:{ fontSize: '1em'}, filtering: false,
        render: rowData => {
          return (
            <div style={{textAlign: 'left'}}
            >${numberWithCommas(Number.parseFloat(rowData.Total_AMT).toFixed(2))}</div>)}},
      { title: 'Balance Due', field:'Current_AMT', editable: "never", filtering: false, cellStyle:{ fontSize: '1em'},
        render: rowData => {
          return (
            <div style={{textAlign: 'left'}}
            >${numberWithCommas(Number.parseFloat(rowData.Current_AMT).toFixed(2))}
            </div>)}},
      { title: 'Status', field:'payment_status', editable: "never", filtering: false,
        render: rowData => {
          return (
          <div style={{ background : rowData.payment_status === 'UNPAID' ? 'red' : '#3bb78f' }}>
           {rowData.payment_status === 'UNPAID' ? 'UNPAID' : 'PAID'}
          </div>)},
            cellStyle: {
            color: 'white',
            textAlign: 'center',
            fontSize: '1em'}}
    ],
    rowDataColumns = [
      { title: 'Reference #', field:'ref_no', editable: "never"
        , cellStyle:{ fontSize: '1em'}
      },
      { title: 'Date', field:'Due_Date', editable: "never",
        render: rowData => {
                date = new Date(`${rowData.Due_Date} `)
                let year = date.getFullYear()
                let month = (1 + date.getMonth()).toString().padStart(2, '0');
                let day = date.getDate().toString().padStart(2, '0');
            return(
              <div>
                {month + '/' + day + '/' + year}
              </div>
            )
          }
        , cellStyle:{ fontSize: '1em'}
      },
      { title: 'Pay Amt.', field:'Total_AMT', editable: "never"
        , cellStyle:{ fontSize: '1em'},
        render: rowData => {
          return (
            <div style={{textAlign: 'right'}}
            >${numberWithCommas(Number.parseFloat(rowData.Total_AMT).toFixed(2))}</div>
          )
        }
      },
      {
        title: 'Due Amt', field: 'Current_AMT', editable: 'never',
        cellStyle:{ fontSize: '1em'},
        render: rowData => {
          return (
            <div style={{textAlign: 'right'}}
            >${numberWithCommas(Number.parseFloat(rowData.Current_AMT).toFixed(2))}</div>
          )
        }
      },
    ];
  // });
  return (

      <div className="InvoiceList">
      <h1 align="center">InvoiceList</h1>
      <button onClick={goBack}>
      Back
      </button>
      <ThemeProvider theme={theme}>
      <MaterialTable 
      title="InvoiceList"
      icons={tableIcons}
      columns={columns} 
      data={tableData} 
      // onSelectionChange={(selectedRows)=>setSelectedRows(selectedRows)}
      // onSelectionChange={(selectedRows)=>console.log(selectedRows)}
      options={{
        sorting:true,
        search:true,
        searchFieldAlignment:"right",
        searchAutoFocus:true,
        searchFieldVariant:"outlined",
        filtering:false,
        paging:true,
        pageSizeOptions:[2,5,10,15,50],
        pageSize:5,
        paginationType:"stepped",
        showFirstLastPageButtons:false,
        paginationPosition:"bottom",
        exportButton:{
           csv: true,
        },
        exportAllData:true,
        exportFileName:"TableData",
        addRowPosition:"first",
        actionsColumnIndex:-1,
        selection:false,
        showSelectAllCheckbox:false,
        showTextRowsSelected:false,
        grouping:false,
        columnsButton:true,
        rowStyle:(data,index)=>index%2==0?{background:"#f5f5f5"}:null,
        headerStyle:{background:"lightgray",fontStyle:"italic",fontSize:19},
        selectionProps:rowData=>({
            disabled:rowData.tax==null,
            color:"primary"
            }),
        }}      
       />
     </ThemeProvider>
    {/*After Click Add button(Pop-up)*/}
    <Dialog open={open} onClose={!open} fullScreen aria-labelledby="form-dialog-title" width={900} height={300} color="primary">
      <DialogTitle id="form-dialog-title">
        {/*<span style={{ fontSize: '1em'}}>{chosen[1]}, Payment ID : {chosen[0]}</span>*/}
      </DialogTitle>
    {/*Display Payment ID with Purple Button and connecte Data from AP Fuction*/}
      <DialogContent>
          <div>
              <InvoiceList 
                           data={chosen.payment_history} 
                           inv_num={chosen.Invoice_ID}
                         
              />                  
          </div>
       <DialogActions
          disableSpacing
          classes={{
            root: tableClass.root,
            spacing: tableClass.spacing
          }}
        >
        {/*Close Dialog after click 'Close' button*/}
          <button className="btn btn-primary"
          onClick={handleClose} style={{fontSize: '2em'}}>
            Close
          </button>
       </DialogActions>
      </DialogContent>
    </Dialog>
     </div>
    );  
  
}
export default Invoice;