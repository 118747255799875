import React, { useState,  forwardRef, useContext, useEffect } from 'react';
import MaterialTable from 'material-table';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import tableIcons from "./components/MaterialTableIcons"
import {Form, FormGroup} from 'react-bootstrap';
import Select from '@material-ui/core/Select';
//Pop-up Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { api_url } from "./authorize";

 const useStyles = makeStyles({
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
    },
    title: {
      fontSize: '2em'
    }
  });
 //table Theme
 const theme = createMuiTheme({
    typography: { h6: {fontSize: '1.4em'}},
  });
 const tableStyles = makeStyles({
    root: {justifyContent: 'space-evenly'},
    spacing: {justifyContent: 'space-evenly'}
  });


function ARSetup() {
 // const [email, setEmailName] = useState([])
 const [data, setData] = useState({})
 //Open Dialog
 const [open, setOpen] = useState(false)//add
 //Make tableStyle
 const tableClass = tableStyles() //add
 const [tableData, setTableData] = useState([])
 const [country, setCountry] = useState({})
 const [popupData, setPopupData] = useState({})
 const [officeData, setOfficeData] = useState([])
 const [officeCountry, setOfficeCountry] = useState([])
 const history = useHistory();
 const goBack = () => {
    history.goBack();
  };
 const handleSubmit = (e) => {
    e.preventDefault()
    const data =  [...tableData];}
 //setOpen(false) meanning is close Dialog
 const handleClose = (e) => {
    e.preventDefault()
    setTableData({})
    setOpen(false)
  }

  const numberWithCommas = (x) => {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
  //Kind of Dictionary (ex if you got "MQC" change name for show "N35"
  const SaveChange = (option) => {
    const { name, value } = option.target
    console.log(name, value)
    setPopupData({...popupData,
      [name] : value
    })
  }
  
  
  // //submit information in Pop-up Dialog
  // const handlePost = (e) => {
  //   e.preventDefault()
  //   // const { name, value } = e.target
  //   //  setPopupData({...popupData,
  //   //   [name] : value
  //   // })
  //    // API call to post to the database
  //    axios({
  //     url:`https://${api_url}/api/receivables`,
  //     method: 'post',
  //     data: popupData,
  //     headers: {
  //        'x-access-token' : token
  //     }
  //   }).then(
  //     res =>{
  //       if(res.data.message){
  //         alert(res.data.message)
  //         console.log(popupData)
  //       }
  //       else if(res.data.validation){
  //         alert(res.data.validation.replace("_", " "))
  //       }
  //       else if(res.data.notLoggedIn){
  //         setAuthenticated(false)
  //         setAuthBody({role: 'visitor'})
  //         alert("You have been logged out due to inactivity. Please log in again.")
  //       }
  //        else{
  //         window.location.reload()
  //         console.log(popupData)
  //       }
  //     }
  //   )
  //   setOpen(false)
  // }
  // const { token, role, setAuthBody, setAuthenticated, authBody } = useContext(AuthorizeContext);//login
  // const [country, setCountry] = useState({})
  // const [loading, setLoading] = useState(true) //login
  // const [profile, setProfile] = useState(authBody || {role: "visitor"})
  //   const hasRole = (profile, roles) =>{
  //   return roles.includes(role)
  // }


//API Calls
//Table looks like this 3
 useEffect(() => {
    const fetchData = async () => {
      const res = await axios({
        url: `https://${api_url}/api/receivables`,
        method: 'get',
        // headers: {
        //   'x-access-token' : token
        // }
      }).then(
        res => {
          if(res.data.message){
            alert(res.data.message)
          }
          else if(res.data.validation){
            alert(res.data.validation.replace("_", " "))
          }
          else if(res.data.notLoggedIn){
            // setAuthenticated(false)
            // setAuthBody({role: 'visitor'})
            // alert("You have been logged out due to inactivity. Please log in again.")
          }
          else{
            // setLoading(false)
            setTableData(res.data)
            // console.log(tableData)
          }
        }
      );
    }
    fetchData();
  }, []);

  // Call to populate users in Dropdown for country
    useEffect(() => {
    const fetchData = async () => {
      const res = await axios({
        url: `https://${api_url}/api/big_form_dropdowns`,
        method: 'get',
        // headers: {
        //   'x-access-token' : token
        // }
      }).then(
        res => {
          if(res.data.message){
            alert(res.data.message)
          }
          else if(res.data.validation){
            alert(res.data.validation.replace("_", " "))
          }
          else if(res.data.notLoggedIn){
            // setAuthenticated(false)
            // setAuthBody({role: 'visitor'})
            alert("You have been logged out due to inactivity. Please log in again.")
          }
          else{
            // setLoading(false)
            setOfficeCountry(res.data.countries)
            // console.log(res.data.countries) // 8 countries shows up
          }
        }
      );
    }
    fetchData();
  }, []);

// When editTherow call DB 2
  const editTheRow = (ROWdata) => {
    // API call to edit a row in the database
    const jd = {
      "job_data": ROWdata
    }
     axios({
      url:`https://${api_url}/api/receivables?mongo_id=${ROWdata._id}`,
      method: 'put',
      data: ROWdata,
      // headers: {
      //    'x-access-token' : token
      // }
    }).then(
      res =>{
        if(res.data.message){
          alert(res.data.message)
        }
        else if(res.data.validation){
          alert(res.data.validation.replace("_", " "))
        }
        else if(res.data.notLoggedIn){
          // setAuthenticated(false)
          // setAuthBody({role: 'visitor'})
          alert("You have been logged out due to inactivity. Please log in again.")
        }
         else{
          console.log(res.data)
          setTableData(res.data)
          // window.location.reload()
        }
      }
    )
  } 

// Call to populate users in Dropdown 1
  useEffect(() => {
    const fetchAcct= async () => {
      const res = await axios({
        url: `https://${api_url}/api/receivables?dropdown=true`,
        method: 'get',
        // headers: {
        //   'x-access-token' : token
        // }
      }).then(
        res => {
          if(res.data.message){
            alert(res.data.message)
          }
          else if(res.data.validation){
            alert(res.data.validation.replace("_", " "))
          }
          else if(res.data.notLoggedIn){
            // setAuthenticated(false)
            // setAuthBody({role: 'visitor'})
            alert("You have been logged out due to inactivity. Please log in again.")
          }
          else{
            // setLoading(false)
            // console.log(res.data)
            setOfficeData(res.data)
          // setEmailName(res.data)
          }
        }
      );
    }
      fetchAcct();
  }, []);

    //submit information in Pop-up Dialog
  const handlePost = (e) => {
    e.preventDefault()
    const { name, value } = e.target
     setPopupData({...popupData,
      [name] : value
    })
     // API call to post to the database
     axios({
      url:`https://${api_url}/api/receivables`,
      method: 'post',
      data: popupData,
      // headers: {
      //    'x-access-token' : token
      // }
    }).then(
      res =>{
        if(res.data.message){
          alert(res.data.message)
          console.log(popupData)
        }
        else if(res.data.validation){
          alert(res.data.validation.replace("_", " "))
        }
        else if(res.data.notLoggedIn){
          // setAuthenticated(false)
          // setAuthBody({role: 'visitor'})
          alert("You have been logged out due to inactivity. Please log in again.")
        }
         else{
          window.location.reload()
          console.log(popupData)
        }
      }
    )
    setOpen(false)
  }

    const columns=[
      { title: 'Account #', field:'Acct_No', editable: 'never',
        defaultSort: 'dsc', cellStyle:{ fontSize: '1em'}},  
      { title: 'Company Name', field:'Office_Name', cellStyle:{ fontSize: '1em'}},  
      { title: 'Contact Last Name', field:'Last_Name', cellStyle:{ fontSize: '1em'}},
      { title: 'Contact First Name', field:'First_Name', cellStyle:{ fontSize: '1em'}},
      { title: 'Phone #', field:'Phone', cellStyle:{ fontSize: '1em'}},
      { title: 'Email', field:'Email_Address', cellStyle:{ fontSize: '1em'}},
      { title: 'Address', field:'Address', cellStyle:{ fontSize: '1em'}},
      { title: 'Contract/PO', field:'PO_number', cellStyle:{ fontSize: '1em'}},
      { title: 'Unit Price', field:'Unit_Price', render: (rowData) => (
      <div style={{ textAlign: 'right' }}>
        ${numberWithCommas(Number.parseFloat(rowData.Unit_Price).toFixed(2))}
      </div>
      
    ),
    cellStyle: { fontSize: '1em' }
  },
     
      { title: 'Term', field:'Term',
        lookup: {
                  'N30' :'N30',
                  'N45' : 'N45',
                  'N60' : 'N60',
                  'N0' : 'Due Upon',
                }, cellStyle:{ fontSize: '1em'}
      },
    ]
  return (
      <div className="ARSetup">
      {/*Title*/}
      <h1 align="center">A/R SETUP</h1>
      <button onClick={goBack}>
      Back
    </button>
      {/*Sub Title*/}
      <ThemeProvider theme={theme}>
      <MaterialTable 
      title="Accounts Receivable Setup"
      icons={tableIcons}
      columns={columns} 
      data={tableData}
      //update everything except Email
      editable={{
          onRowUpdate:(newRow)=>new Promise((resolve,reject)=>{           
            editTheRow(newRow)
            setTimeout(()=>resolve(),500)
              console.log(newRow)
        }),
      }}  
      actions={[
        {
                  icon: tableIcons.Add,
                  tooltip: 'Add User',
                  isFreeAction: true,
                  onClick: (event) => {
                    setOpen(true)
                  }
                }
        ]}
      //Grab SelectedRows and showing up console for web page
      onSelectionChange={(selectedRows)=>console.log(selectedRows)}
      options={{
        sorting:true,
        search:true,
        searchFieldAlignment:"right",
        searchAutoFocus:true,
        searchFieldVariant:"outlined",
        filtering:false,
        paging:true,
        pageSizeOptions:[2,5,10,15,50],
        pageSize:5,
        paginationType:"stepped",
        showFirstLastPageButtons:false,
        paginationPosition:"bottom",
        exportButton:{
           csv: true,
        },
        exportAllData:true,
        exportFileName:"TableData",
        addRowPosition:"first",
        actionsColumnIndex:-1,
        selection:false,
        showSelectAllCheckbox:false,
        showTextRowsSelected:false,
        grouping:false,
        columnsButton:true,
        rowStyle:(data,index)=>index%2==0?{background:"#f5f5f5"}:null,
        headerStyle:{background:"lightgray",fontStyle:"italic",fontSize: 15},
        selectionProps:rowData=>({
            disabled:rowData.tax==null,
            color:"primary"
            }),
        }}      
       />
     </ThemeProvider>
     {/*After Click Add button(Pop-up)*/}
    <Dialog open={open} onClose={!open} aria-labelledby="form-dialog-title">
     {/*Pop-UP Title*/}
      <DialogTitle id="form-dialog-title">
        <span style={{ fontSize: '2em'}}>NEW CUSTOMER</span>
      </DialogTitle>
      <DialogContent>
        <Form className="arForm">
        {/*first row & box*/}
          <FormGroup>
            <div>
              <div>
                <label>Dis/Hos Office*</label>
              </div>
            <div>
            <Select
              name = "Office_Name"
              onChange={SaveChange}
              style={{fontSize: '2rem'}}
              fullWidth
              required
              variant="outlined"
            >
                <option selected value={""}>Choose Office</option>
                {
                  officeData ?
                  officeData.map((option) =>

                  (
                  <option
                          key={option.mongo_id}
                          value={option}
                  >
                    {
                      `${option.drop_string}`                     
                    }
                  </option>
                ))
                  :
                  null
                }

              </Select>
            </div>
          </div>
        </FormGroup>

        <FormGroup>
          <div>
            <div>
              <label>Last Name*</label>
            </div>
            <div>
            <input
              name="Last_Name"
              type="text"
              onChange={SaveChange}
              defaultValue={""}
              required
            />
            </div>
          </div>
        </FormGroup>

          <FormGroup>
            <div>
              <div>
                <label>First Name*</label>
              </div>
              <div>
              <input
                name="First_Name"
                type="text"
                defaultValue= {""}
                onChange={SaveChange}
                style={{fontSize: '2rem'}}
                required
              />
              </div>
            </div>
          </FormGroup>

        <FormGroup>
          <div>
            <div>
              <label>Middle Name</label>
            </div>
            <div>
            <input
              name="Middle_Name"
              type="text"
              onChange={SaveChange}
              defaultValue={""}
            />
            </div>
          </div>
        </FormGroup>

          <FormGroup>
            <div>
            <div>
              <label>Phone*</label>
            </div>
            <div>
            <input
              name="Phone"
              type="text"
              defaultValue={""}
              onChange={SaveChange}
              required
            />
            </div>
            </div>
          </FormGroup>
          <FormGroup>
          <div>
            <div>
              <label>Billing Email Address*</label>
            </div>
            <div>
              <input
                name="Email_Address"
                type="text"
                onChange={SaveChange}
                defaultValue={""}
                required
              />
            </div>
          </div>
          </FormGroup>
          <FormGroup>
            <div>
            <div>
              <label>Street*</label>
            </div>
            <div>
            <input
              name="Street"
              type="text"
              onChange={SaveChange}
              defaultValue={""}
              required
            />
            </div>
            </div>
          </FormGroup>
          <FormGroup>
            <div>
            <div>
              <label>city*</label>
            </div>
            <div>
            <input
              name="City"
              type="text"
              onChange={SaveChange}
              defaultValue={""}
              required
            />
            </div>
            </div>
          </FormGroup>
          <FormGroup>
            <div>
            <div>
              <label>State/Province</label>
            </div>
            <div>
            <input
              name="State"
              type="text"
              onChange={SaveChange}
              defaultValue={""}
            />
            </div>
            </div>
          </FormGroup>
          <FormGroup>
            <div>
            <div>
              <label>Postal Code*</label>
            </div>
            <div>
            <input
              name="Zip_Code"
              type="text"
              onChange={SaveChange}
              defaultValue={""}
              required
            />
            </div>
            </div>
          </FormGroup>
          
           <FormGroup>
            <div>
              <div>
                <label>Country*</label>
              </div>
            <div>
            <Select
              name = "Country"
              onChange={SaveChange}
              style={{fontSize: '2rem'}}
              fullWidth
              required
              variant="outlined"
            >
                <option selected value={""}>Choose Country</option>
                {
                  officeCountry ?
                  officeCountry.map((option) =>

                  (
                  <option
                          key={option.mongo_id}
                          value={option[1]}
                  >
                    {
                      `${option[0]}`                     
                    }
                  </option>
                ))
                  :
                  null
                }

              </Select>
            </div>
          </div>
        </FormGroup>

           <FormGroup>
          <div>
            <div>
              <label>Contract/PO Number*</label>
            </div>
            <div>
            <input
              name="PO_number"
              type="text"
              defaultValue={""}
              onChange={SaveChange}
            />
            </div>
          </div>
        </FormGroup>

        <FormGroup>
            <div>
            <div>
              <label>Unit Price</label>
            </div>
            <div>
            <input
              name="Unit_Price"
              type="number"
              onChange={SaveChange}
              defaultValue={""}
              required
            />
            </div>
            </div>
          </FormGroup>

         
          <FormGroup>
          <div>
            <div>
              <label>Invoice Preference: </label>
            </div>
            <div>
              <Select
                name="Invoice_pref"
                native
                defaultValue={""}
                onChange={SaveChange}
                style={{fontSize: '2rem'}}
                required
                variant="outlined"
              >
                <option selected value={""}>Choose Preference</option>
                <option value="Email">Email</option>
                <option value="Print">Print</option>
              </Select>
          </div>
          </div>
        </FormGroup>


        <FormGroup>
            <div>
            <div>
              <label>Payment Term*</label>
            </div>
            <Select
              name = "Term"
              onChange={SaveChange}
              style={{fontSize: '2rem'}}
              fullWidth
              required
              defaultValue=""
              variant="outlined"
            >
              <option selected value={""}>Choose Term</option>
              <option value="N30">N30</option>
              <option value="N45">N45</option>
              <option value="N60">N60</option>
              <option value="N0">Due Upon</option>
            </Select>
            </div>
          </FormGroup>
         
      {/*Pop-UP boxes actions*/} 
      <DialogActions
        disableSpacing
        classes={{
          root: tableClass.root,
          spacing: tableClass.spacing
        }}
      >
      {/*When Click 'Add' Button...*/}
        <button className="btn btn-success" 
        // onClick={handlePost}
        type="submit"
        variant="contained" style={{fontSize: '2em'}}>
          Add
        </button>
      {/*Close Dialog after click 'Cancel' button*/}
        <button className="btn btn-danger"
        onClick={handleClose} style={{fontSize: '2em'}}>
          Cancel
        </button>
      </DialogActions>
      </Form>
    </DialogContent>
  </Dialog>
    </div>
  );
}

export default ARSetup;
