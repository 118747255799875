import {React, useState, useEffect, useContext } from 'react';
import { Link, Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '26em',
    width: '15%'
  },
}));

const AccountPage = () => {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const classes = useStyles();
  console.log(data)
  const numberWithCommas = (x) => {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div>
      <div className="col-md-6">
        <div>
          <h3>Current Status</h3>
        </div>
        <div className="col-md-12">
          <div className="col-md-6">
            <h4>Account Receivable Balance</h4>
          </div>
        </div>
        <div className="col-md-12">
          <div className="col-md-6">
            <br />
            <h4>Account Payable Balance (Total Due Amount)</h4>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="row">
          <Link type="button" to={'/ARSetup'} className="btn btn-primary button-info">
            A/R Setup/Modify</Link>
        </div>
        <div className="row">
          <Link type="button" to={'/APSetup'} className="btn btn-primary button-info">
            A/P Setup/Modify
          </Link>
        </div>
        <div className="row">
          <br/>
          <br/>
          <br/>
        </div>
        <div className="row">
          <Link type="button" to={'/Invoice'} className="btn btn-primary button-info">Invoice</Link>
        </div>
        <div className="row">
          <Link type="button" to={'/AReceivable'} className="btn btn-primary button-info">Account Receivable</Link>
        </div>
        <div className="row">
          <Link type="button" to={'/ARPayment'} className="btn btn-primary button-info">Apply A/R Payment</Link>
        </div>
        <div className="row">
          <br/>
          <br/>
          <br/>
        </div>
        <div className="row">
          <Link type="button" to={'/EnterBill'} className="btn btn-primary button-info">Enter Bill</Link>
        </div>
        <div className="row">
          <Link type="button" to={'/Payable'} className="btn btn-primary button-info">Account Payable</Link>
        </div>
      </div>
    </div>
  )
};

export default AccountPage;
